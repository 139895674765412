.updateCard {
    width: 80%;
    margin: auto;
    border: none;
    box-shadow: 2px 2px 15px #ddd;
    border-radius: 15px;
    margin-bottom: 100px !important;
}

.cardRow {
    width: 90% !important;
}

.btn,
.btn:hover,
.btn:focus {
    border: none;
    border-radius: 7px;
    background: #00115b !important;
    color: #fff;
    width: 50%;
}

.btn:hover {
    transform: scale(1.01);
    box-shadow: 2px 2px 10px #ccc;
}

.btn:disabled {
    color: #fff;
}

.input {
    border-radius: 5px !important;
    color: rgba(0,0,0,0.2) !important;
    font-weight: 600;
}

.searchBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchInput {
    padding: 4px 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.searchInput:hover,
.searchInput:active,
.searchInput:focus {
    outline: none;
    border-color: #d9d9d9 !important;
}

.searchBtn {
    background: var(--primary-blue) !important;
    color: #fff !important;
    border: 1px solid var(--primary-blue);
}

.searchBtn:hover,
.searchBtn:focus {
    outline: none !important;
    border: 1px solid var(--primary-blue);
    background: var(--primary-blue) !important;
}

.userCard {
    border: none !important;
    box-shadow: 1px 1px 5px #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
}

.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--primary-blue) !important;
}

.username {
    word-break: break-all;
    display: block;
    font-size: 15px;
    line-height: 18px;
    color: var(--light-text);
}
.userCardDisabled {
    border: none !important;
}

.iconDisabled {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #bbb !important;
}

.usernameDisabled {
    background: #bbb !important;
    height: 5px;
}

.activateEdit, .activateEdit:hover, .activateEdit:focus{
    background-color: #00115b;
    color: #fff;
    border: 1px solid#00115b !important;
}

@media only screen and (min-width: 750px) and (max-width : 900px) {
    .updateCard {
        width: 70%;
    }

    .cardRow {
        width: 70% !important;
    }

}

@media only screen and (max-width : 750px) {
    .updateCard {
        width: 100%;
    }

    .cardRow {
        width: 90% !important;
    }

}