.collapse {
    background: #fff;
    box-shadow: 1px 1px 7px #ddd;
    border: none;
    border-radius: 20px;
}

.previewBtn,
.previewBtn:hover,
.previewBtn:focus {
    color: var(--primary-blue);
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 10px;
    outline: none !important;
    transition: all 0.5s ease-in;
}

.previewBtn:hover {
    box-shadow: 1px 1px 10px #ddd;
    transform: scale(1.01);
}


/* Preview page styles */

.previewCard {
    box-shadow: 2px 2px 8px #ddd;
    border-radius: 20px;

}

.collapse {
    border-radius: 15px 15px 0 0;
}

.panelInner {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

ul.list {
    color: rgba(0, 0, 0, 0.6);
    list-style-type: circle;
}