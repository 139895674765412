.container {
    width: 100% !important;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-card {
    background: #fff;
    box-shadow: 2px 2px 10px #eee;
    border-radius: 20px;
}

.landing-logo {
    width: 120px;
}

.landing-icon {
    font-weight: 700;
    color: #fff;
}

.landing-btn{
    box-shadow: 2px 2px 10px #eee;
}

.circular {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background: var(--primary-green) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px !important;
    padding: 10px;
}

.rectangular {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -10px;
}

.landing-btn-text{
    color: #fff !important;

}

@media only screen and (max-width: 499px) {
    .container {
        display: block;
    }
    
    .home-card {
        height: inherit;
        border-radius: 0;
        max-width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-logo {
        width: 90px;
    }
}