.sideNavParent {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 250px;
    height: 100%;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
}

.sideNav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-around;
    height: 97vh;
    width: 100%;
    background: #090036;
    border-radius: 15px;
    padding: 10px 0 150px 0;
}
.logoParent{
    display: flex;
    margin: auto !important;
}

.logo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    margin: 0;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.logoIcon {
    width: 45px !important;
    height: 45px !important;
}
.gems{
    position: relative;
    left: 4px;
}
.aff{
    position: relative;
    right: 4px;
}
.navItems {
    padding: 0;
    padding-left: 10px;
    width: 100%;
    position: relative;
}

.logoutBtn {
    display: flex;
    justify-content: space-around;
    align-items: center !important;
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    height: 40px;
    font-size: 15px;
    color: #fff !important;
    gap: 10px;
    border: none;
    border-radius: 15px;
}

.logoutBtn:hover, .logoutBtn:focus, .logoutBtn:active {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
    border: none;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
    color: #fff !important;
}

.navItem {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    width: 100%;
    padding: 15px 12px;
    border-radius: 25px 0 0 25px;
    color: #ccc;
    font-weight: 600;
    letter-spacing: 0.5px;

}

.navItem:hover {
    background: #fff;
    color: #090036 !important;
    letter-spacing: 0.2px;
}

.navItem:hover::after {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background: #090036;
    right: 0;
    bottom: 100%;
    border-bottom-right-radius: 25px;
    box-shadow: 0 20px 0 #fff;
}

.navItem:hover:before {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    background: #090036;
    right: 0;
    top: 100%;
    border-top-right-radius: 25px;
    box-shadow: 0 -20px 0 #fff;
}

.link-text {
    color: #91A2B8 !important;
}

.navItem a {
    display: block;
}

.navIcon {
    color: #91A2B8;
}

.seniorman{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
    flex-direction: column;
    gap: 15px;
}
.mainContent {
    width: 100%;
    padding: 15px 25px;
    margin-left: 250px;
}

.stickyNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 100%;
}
/* Bottom Nav */
.bottomNavParent {
    display: none;
}

/* Bottom Nav */

/* Media queries */
/* Medium screen devices (Tablet screens */
@media only screen and (max-width: 950px) {
    .aff-name{
        display: none;
    }
    .sideNavParent {
        width: 90px;
    }
    .gems{
        display: none;
    }
    .aff{
        right: 0;
    }
    .logo {
        width: 55px;
        height: 55px;
    }

    .logoIcon {
        width: 40px !important;
        height: 40px !important;
    }

    .navItems {
        padding: 0;
    }

    .navItem {
        border-radius: 0;
        justify-content: center;
    }

    .navItem:hover {
        background: none;
        letter-spacing: 0.2px;
    }

    .navItem:hover::after,
    .navItem:hover:before {
        display: none;
    }

    .navItem .link-text {
        display: none !important;
    }

    .mainContent {
        margin-left: 90px;
    }
}

/* Small screen devices (Mobile Phones etc) */
@media only screen and (max-width: 530px) {
    .sideNavParent {
        display: none;
    }

    .mainContent {
        margin: 0;
        width: 100%;
    }

    .bottomNavParent {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 70px;
        transition: all 2s ease;
    }

    .bottomNav {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: calc(100% - 20px);
        height: 60px;
        border-radius: 15px;
        background: #090036 !important;
    }

    .bottomNav a:active {
        background-color: #fff;
    }

    .logoutText {
        display: none !important;
    }
}