@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=PT+Sans&family=Ubuntu&display=swap');

body {
  margin: 0;
  font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --light-text: #91A2B8;
  --primary-green: #24E9A3;
  --primary-blue: #00115b;
  --gradient-color1: #24E9A3;
  --gradient-color2: #1BCACD;
  --gradient-color3: #adbcff;
  --danger: #E92424;
  --success: #00C851;
  --warning: #FF8800;
  --divider: #F0F0F0;
}

.ant-modal-body {
  padding: 0 !important;
}

.myCard .ant-card-body {
  padding: 15px;
}

.userCard .ant-card-body {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  gap: 30px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.registerCard .ant-card-body {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.not-found{
    margin: auto;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 50%;
    height: auto;
    padding: 8px;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    gap: 18px;
}

@media only screen and (max-width: 768px){
    .not-found{
        width: 100%;
    }
}

.not-found img{
    width: 100%;
    height: auto;
}

.not-found button{
    cursor: pointer;
    background-color: transparent;
    border: 2px solid #ff6663;
    padding: 8px 16px;
    color: #ff6663;
    border-radius: 10px;
}