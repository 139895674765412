 .layout {
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-card {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.cardHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
}

.loginText {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 10px;
    color: var(--primary-blue);
}

#usernameText {
    font-size: 15px;
}

.inputArea {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px 75px 75px 0px;
    padding: 15px 0;
    width: 90%;
    margin: 10px auto 0 auto;
}
.loginArea {
    padding: 8px 0;
    width: 90%;
    margin: 10px auto 0 auto;
}

.formIcon {
    font-size: 20px;
    margin: 0 8px 0 10px;
    color: #ccc;
}

.eyeIcon {
    font-size: 15px;
    margin-right: 50px;
    color: #ccc;
    cursor: pointer;
}

.divider {
    margin: 5px 0;
    border-width: 1px;
}
.ant-input-suffix{
    padding-right: 20px;
}
.loginBtn,
.loginBtn:focus,
.loginBtn:active {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
    border-radius: 0 30px 30px 0;
    border: none;
    box-shadow: 0px 4px 8px 1px rgba(27, 202, 205, 0.2);
    font-weight: 500;
    font-size: 16px;
    padding: 15px !important;
    width: 100%;
}

.loginBtn:hover {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    margin-left: 12px !important;
}

.loginBtnIcon {
    font-size: 30px;
    color: white;
}

.otherLinks {
    width: 90%;
    margin: 0 auto 40px auto;

}

.forgotPassword {
    display: block;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--primary-green);
    margin-top: 10px;
    text-decoration: underline;
}

.register {
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--light-text);
    margin: 15px 0;
}

.backHome {
    display: block;
    width: 90%;
    margin: auto;
    font-size: 15px;
    line-height: 18px;
    color: var(--light-text);
    margin-bottom: 35px;
    margin-top: 15px;
}

@media only screen and (max-width : 550px) {
    .layout {
        display: inherit;
    }
    .login-card {
        display: flex;
        align-items: center;
        justify-content: center;
        height: inherit !important;
    }
}