.searchBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchInput {
    padding: 4px 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.searchInput:hover,
.searchInput:active,
.searchInput:focus {
    outline: none;
    border-color: #d9d9d9 !important;
}

.searchBtn {
    background: var(--primary-blue) !important;
    color: #fff !important;
    border: 1px solid var(--primary-blue);
}

.searchBtn:hover,
.searchBtn:focus {
    outline: none !important;
    border: 1px solid var(--primary-blue);
    background: var(--primary-blue) !important;
}

.searchResult {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #ccc;
    border-radius: 20px;
    min-height: 100px;
    padding: 15px 30px !important;
}

.searchResultTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: var(--primary-blue);
    font-size: 22px !important;
}

.searchResultText {
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
}