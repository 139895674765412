.navbar {
    background-color: #fff;
    box-shadow: 2px 2px 15px #eee;
    padding: 10px 25px;
    min-height: 80px;
}

.gems-logo {
    width: 50px !important;
}

.modal {
    padding: 25px;
    background: #fff;
}

.password-input {
    width: 100%;
    border-radius: 8px;
}

.styling-gel {
    display: flex;
    justify-content: center;
    align-items: center;
}

.done-change {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.proceed-btn {
    width: 100% !important;
    border: none;
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
}

.proceed-btn:hover,
.proceed-btn:focus,
.proceed-btn:active {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
    color: #fff !important;
    border: none;
}

.logoutBtn {
    display: flex;
    justify-content: space-around;
    align-items: center !important;
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    height: 30px;
    font-size: 15px;
    color: #fff !important;
    gap: 10px;
    border: none;
    border-radius: 15px;
}

.logoutBtn:hover,
.logoutBtn:focus,
.logoutBtn:active {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
    color: #fff !important;
    border: none;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
}


/* Agent Profile */
.profiler{
    padding: 40px;
    background: #fff;
    width: 50%;
    margin: auto;
    margin-top: 50px;
    box-shadow: 2px 2px 8px #ddd;
    border-radius: 15px;
}
.cp-btn{
    width: 100% !important;
}
.cp-btn, .proceed-password-btn {
    border: none;
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2)) !important;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
}

.cp-btn,.proceed-password-btn:hover,
.cp-btn,.proceed-password-btn:focus,
.cp-btn,.proceed-password-btn:active {
    background: linear-gradient(to right, var(--gradient-color1), var(--gradient-color2));
    color: #fff !important;
    border: none;
}

.cancel-password-btn {
    border: none;
    background: #e92424b3 !important;
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
}

.cancel-password-btn:hover,
.cancel-password-btn:focus,
.cancel-password-btn:active {
    background: #e92424b3 !important;
    color: #fff !important;
    border: none;
}



/* Agent Profile ends */

@media only screen and (max-width: 600px) {
    .profiler {
        width: 80% !important;
    }
}

@media only screen and (max-width: 530px) {
    .logoutText {
        display: none !important;
    }
}