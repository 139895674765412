.registerCard{
    width: 40%;
    margin: auto;
    border: none;
    box-shadow: 2px 2px 15px #ddd;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 100px !important;
}

.cardRow{
    width: 80% !important;
}

.btn, .btn:hover, .btn:focus{
    border: none;
    border-radius: 7px;
    background: #00115b !important;
    color: #fff;
    width: 50%;
}

.btn:hover{
    transform: scale(1.01);
    box-shadow: 2px 2px 10px #ccc;
}

.btn:disabled{
    color: #fff;
}

.input{
    border-radius: 5px !important;
}



@media only screen and (min-width: 750px) and (max-width : 900px) {
    .registerCard{
        width: 70%;
    }
    .cardRow{
        width: 70% !important;
    }
    
}
@media only screen and (max-width : 750px) {
    .registerCard{
        width: 100%;
    }
    .cardRow{
        width: 90% !important;
    }
    
}