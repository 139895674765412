.menu{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    list-style-type: none;
    background: var(--primary-blue);
    color: #fff;
    padding: 0;
    margin: 0;
    width: 100;
    border: 2px solid var(--primary-blue);
    margin-bottom: 20px;
}

.menuItem{
    padding: 10px 18px;
    transition: all 0.6s ease-out;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.menuItem:hover{
    background: #fff;
    color: var(--primary-blue);
    cursor: pointer;
}

.nav-text{
    display: inline;
}

@media only screen and (min-width: 630px) and (max-width: 770px){
    .icon{
        display: none;
    }
}
@media only screen and (max-width: 630px){
    .menu{
        width: 100%;
    }
    .menuItem{
        width: 100%;
    }
    .nav-text{
        display: none;
    }
}